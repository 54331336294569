<template>
  <div class="card-custom" v-if="souscription != false && params != false">
    <b-row >
      <b-col md="8" offset-md="2">
        <b-card class="mt-3" no-body>
          <b-card-body>
            <b-row>
              <b-col md="7">
                <b-card-text>Fiche client #{{souscription.id}} - {{user.nom}} {{user.prenom}} 
                  <b-button class="ms-1" size="sm" :variant="$store.api.getParam(params.SouscriptionStatus, souscription.status_id).color">{{$store.api.getParam(params.SouscriptionStatus, souscription.status_id).titre}}</b-button>
                </b-card-text>
              </b-col>              
              <b-col md="5" class="text-end" >
                <b-button class="ms-1" variant="success" size="sm" @click="save('info')">Sauvegarder</b-button>
                <b-button class="ms-1" variant="primary" size="sm" @click="edit = !edit">Modification</b-button>
                <b-button class="ms-1" v-if="souscription.status_id === 5" variant="danger" size="sm" @click="getResiliation()">Resiliation</b-button>
                <b-button class="ms-1" v-if="souscription.status_id === 4" variant="danger" size="sm" @click="$refs.modalAnnulation.show()">Annulation</b-button>
              </b-col>
            </b-row>
            <hr class="my-2">
            <b-row class="mt-3">
              <p class="h5 text-primary" v-b-toggle.locataire>Coordonnées Locataire</p>
              <b-collapse id="locataire" visible>
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Nom">
                      <b-form-input v-model="user.nom" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Prenom">
                      <b-form-input v-model="user.prenom" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Email">
                      <b-form-input v-model="user.email" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Telephone">
                      <b-form-input v-model="user.telephone" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Date de naissance">
                      <b-form-input type="date" v-model="user.date_naissance" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Adresse" v-if="souscription.logement.adresse != null">
                      <b-form-input v-model="souscription.logement.adresse" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Code Postal" v-if="souscription.logement.cp != null">
                      <b-form-input v-model="souscription.logement.cp" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Ville" v-if="souscription.logement.ville != null">
                      <b-form-input v-model="souscription.logement.ville" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Loyer">
                      <b-form-input v-model.number="souscription.loyer" :readonly="!edit" @focusout="price()"></b-form-input>
                    </b-form-group>
                  </b-col>                  
                  <b-col md="3">
                    <b-form-group label="Type">
                      <select class="form-control" v-model="souscription.meublee" :disabled="!edit" @change="price()">
                        <option :value="false">Vide</option>
                        <option :value="true">Meublée</option>
                      </select>                      
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Coup dur">
                      <select class="form-control" v-model="souscription.coup_dur" :disabled="!edit" @change="price()">
                        <option :value="false">Non</option>
                        <option :value="true">Oui</option>
                      </select>                      
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Cotisation">
                      <b-form-input v-model="souscription.prix_mensuel" :readonly="true"></b-form-input>
                    </b-form-group>
                  </b-col>                  
                  <b-col md="3" v-if="souscription.logement.date_bail != null">
                    <b-form-group label="Date effet">
                      <input class="form-control" type="date" v-model="souscription.logement.date_bail"  :disabled="!edit" />
                    </b-form-group>
                  </b-col>                  
                </b-row>
              </b-collapse>
            </b-row>
            <hr class="m">
            <b-row class="mt-3" v-if="souscription.proprietaire_id != null && souscription.agence === false">
              <p class="h5 text-primary" v-b-toggle.proprietaire>Coordonnées Proprietaire</p>
              <b-collapse id="proprietaire" visible>
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Nom">
                      <b-form-input v-model="proprietaire.nom" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Prenom">
                      <b-form-input v-model="proprietaire.prenom" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Email">
                      <b-form-input v-model="proprietaire.email" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Telephone">
                      <b-form-input v-model="proprietaire.telephone" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>
            <b-row class="mt-3" v-if="souscription.proprietaire_id != null && souscription.agence === true">
              <p class="h5 text-primary" v-b-toggle.proprietaire>Coordonnées Agence</p>
              <b-collapse id="proprietaire" visible>
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Nom agence">
                      <b-form-input v-model="proprietaire.nom" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Email">
                      <b-form-input v-model="proprietaire.email" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>                  
                  <b-col md="3">
                    <b-form-group label="Telephone">
                      <b-form-input v-model="proprietaire.telephone" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="3">
                    <b-form-group label="Nom agent immobilier">
                      <b-form-input v-model="proprietaire.prenom" :readonly="!edit"></b-form-input>
                    </b-form-group>
                  </b-col>                   -->
                </b-row>
              </b-collapse>
            </b-row>
            <hr class="my-2">
            <b-row class="mt-3">
              <p class="h5 text-primary" v-b-toggle.events>Journal des evements du client</p>
              <b-collapse id="events" visible>
                <p v-for="(e,i) in events" :key="i" class="text-dark">{{e.titre}} <small>{{$store.api.timestampToDate(e.date_create)}}</small></p>
              </b-collapse>
            </b-row>
            <hr class="my-2">
            <b-row class="mt-3">
              <p class="h5 text-primary" v-b-toggle.documents>Documents</p>
              <b-collapse id="documents" visible>
                <b-row>
                  <b-col md="3" v-if="souscription.docs.attestation != null">
                    <b-button block variant="success" :href="souscription.docs.attestation" target="_blank">Attestation provisoire</b-button>
                  </b-col>
                  <b-col md="3" v-if="souscription.docs.contrat != null">
                    <b-button block variant="success" :href="souscription.docs.contrat" target="_blank">Contrat</b-button>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-row>
            <hr class="my-2">
            <b-row class="mt-3">
              <p class="h5 text-primary" v-b-toggle.cotisation>Informations</p>
              <b-collapse id="cotisation" visible>
                <p class="h6">Montant loyer: <strong>{{souscription.loyer}}€ <small v-if="souscription.provisoir === true">(attestation provisoire)</small></strong></p>
                <p class="h6">Montant de la cotisation: <strong>{{souscription.paiement.periode === 'annuel' ? souscription.prix_annuel : souscription.prix_mensuel}}€ / {{souscription.paiement.periode === 'annuel' ? 'an' : 'mois'}} <small v-if="souscription.provisoir === true">(attestation provisoire)</small></strong></p>
                <p class="h6">Coup dur: <strong>{{souscription.coup_dur ? 'Oui' : 'Non'}}</strong></p>
                <p class="h6">Location meublée: <strong>{{souscription.meublee ? 'Oui' : 'Non'}}</strong></p>
                <p class="h6" v-if="souscription.coup_dur">Montant Coup dur: <strong>{{getCoupDur()}}€</strong></p>
                <p class="h6" v-if="souscription.apt_ref != null">Code appartement: <strong>{{ souscription.apt_ref }}</strong></p>
              </b-collapse>
            </b-row>
            <b-row class="mt-3">
              <p class="h5 text-primary" v-b-toggle.transactions>Transactions</p>
              <b-collapse id="transactions">
                <div class="d-block text-end mb-3">
                  <b-button size="sm" variant="primary" v-b-modal.modalPaiement>Ajouter</b-button>
                </div>
                <b-table-simple stacked="md" striped hover>
                  <b-thead>
                    <b-tr>
                      <b-th>ID</b-th>
                      <b-th>Date</b-th>
                      <b-th>Statut</b-th>
                      <b-th>Montant</b-th>                      
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(p, i) in paiements" :key="i" :variant="p.remboursement ? 'danger' : ''">                      
                      <b-td>{{ p.id }}</b-td>
                      <b-td>{{$store.api.timestampToDate(p.date_create, true)}}</b-td>
                      <b-td>
                        <b-dropdown size="sm" :variant="paiementStatus(p.status_id).color">
                          <template #button-content>
                            {{ paiementStatus(p.status_id).titre }}
                          </template>
                          <b-dropdown-item v-for="(s,i) in params.PaiementStatus" :key="i" @click="changePaiement(p, s.id)">{{ s.titre }}</b-dropdown-item>                          
                        </b-dropdown>                        
                      </b-td>
                      <b-td>{{$store.api.price(p.montant)}}€</b-td>                      
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-collapse>
            </b-row>
            <hr class="my-2">
            <b-row class="mt-3">
              <p class="h5 text-primary" v-b-toggle.cotisation>Lien inscription</p>
              <b-collapse id="cotisation">
                <p class="h6" v-if="souscription.provisoir">Lien attestation: {{$store.api.urlAPP}}/#/inscription/locataire?uuid={{souscription.uuid}}</p>
                <p class="h6" v-else>Lien souscription: {{$store.api.urlAPP}}/#/zero-depot?uuid={{souscription.uuid}}</p>
                <p class="h6"  v-if="souscription.status_id === 4">Lien proprietaire: {{$store.api.urlAPP}}/#/inscription/proprietaire?uuid={{souscription.uuid}}</p>
                <b-button v-if="souscription.status_id === 4" variant="outline-primary" @click="emailProprietaire()">Mail Proprietaire</b-button>
              </b-collapse>              
            </b-row>
            <hr class="my-2">
            <b-row class="mt-3">
              <p class="h5 text-primary" v-b-toggle.method_paiement>Changement de methode de paiement</p>
              <b-collapse id="method_paiement">
                <p>Actuellement {{ user.payement.type_id === 2 ? `la CB terminant par ${user.payement.label} et expirant le ${user.payement.expire}` : `le RIB  ${user.payement.label}`}} est enregistré.</p>
                <b-row class="mb-3">
                  <b-col md="6">
                    <select v-model.number="souscription.paiement.type_id" class="form-control">
                      <option :value="2">Carte de crédit / débit</option>
                      <option :value="1">Prélèvement bancaire (SEPA)</option>
                    </select>                    
                  </b-col>
                  <b-col md="6">
                    <div class="text-center">
                      <b-button v-if="!loadMethod" variant="primary" @click="saveMethod()">Changer</b-button>
                      <b-button v-else variant="primary"><b-spinner type="grow" label="Spinning"></b-spinner></b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="souscription.paiement.type_id === 1">
                  <b-col md="4">
                    <b-form-group label="Titulaire du compte">
                      <b-form-input type="text" v-model="rib.titulaire"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="8">
                    <b-form-group label="IBAN">
                      <b-form-input type="text" v-model="rib.iban" maxlength="33"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row> 
                <b-row v-else>
                  <b-col md="6">
                    <b-form-group label="N° de CB">
                      <b-input-group class="mt-3">
                        <template #append>
                          <img v-if="checkCB() != false" width="40" loading="lazy" :src="require('@/assets/images/'+checkCB())" alt="" class="image-6" />
                        </template>
                        <b-form-input type="text" v-model="cb.nb" maxlength="19"></b-form-input>                      
                      </b-input-group>                      
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Nom sur la carte">
                      <b-form-input type="text" v-model="cb.name"></b-form-input>
                    </b-form-group>
                  </b-col>                  
                  <b-col md="6">
                    <b-form-group label="Date expire">
                      <b-form-input placeholder="03/2029" type="text" v-model="cb.date" maxlength="7"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="CVV">
                      <b-form-input placeholder="1234" type="text" v-model="cb.cvv" maxlength="4"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>                                                
              </b-collapse>              
            </b-row>
            <hr class="my-2">
            <b-row class="mt-3">
              <b-row>
                <b-col md="6">
                  <p class="h5 text-primary" v-b-toggle.commentaire>Commentaire</p>
                </b-col>
              </b-row>
              <b-collapse id="commentaire" visible>
                <b-form-textarea v-model="souscription.commentaire" rows="7" disabled id="commentaire_input"></b-form-textarea>
                <b-form-group label="Votre commentaire:">
                  <b-input-group>
                    <b-form-input v-model="commentaireTmp" @keyup.enter="addCommentaire()"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="success" @click="addCommentaire()">
                        <i class="fas fa-paper-plane"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-collapse>
            </b-row>
            <hr class="my-2">
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="modalResiliation" id="modalResiliation" hide-footer title="Resiliation">      
      <b-row>
        <b-col md="6">
          <b-form-group label="Statut">
            <select class="form-control" v-model="resiliation.status_id">              
              <option :value="7">Resilié</option>
              <option :value="8">Resilié impayé</option>
              <option :value="11">Résilié - Refus propriétaire</option>
              <option :value="12">Annulé</option>
            </select>
          </b-form-group>            
        </b-col>
      </b-row>
      <b-row v-for="(p,i) in resiliation.paiements" :key="i" class="mt-3">
        <b-col md="4">
          <b-form-group label="Date">
            <b-form-input :value="$store.api.timestampToDate(p.date, false)" readonly></b-form-input>
          </b-form-group>            
        </b-col>
        <b-col md="4">
          <b-form-group label="Payer">
            <b-form-input v-model="p.montant" readonly></b-form-input>
          </b-form-group>            
        </b-col>
        <b-col md="4">
          <b-form-group label="A rembourser">
            <b-form-input type="number" min="0" :max="p.montant" v-model.number="p.remboursement"></b-form-input>
          </b-form-group>            
        </b-col>
      </b-row>
      <b-row class="mt-3">        
        <b-col md="6">
          <b-form-group label="Partenaire">
            <b-form-input v-model="resiliation.partenaire_raison" readonly></b-form-input>
          </b-form-group>            
        </b-col>
        <b-col md="6">
          <b-form-group label="Decomission partenaire">
            <b-form-input v-model="resiliation.parteneaire_decom"></b-form-input>
          </b-form-group>            
        </b-col>
      </b-row>
      <b-row class="mt-3">                
        <b-col md="6">
          <b-form-group label="Apporteur">
            <b-form-input v-model="resiliation.apporteur_raison" readonly></b-form-input>
          </b-form-group>            
        </b-col>
        <b-col md="6">
          <b-form-group label="Decomission apporteur">
            <b-form-input v-model="resiliation.apporteur_decom"></b-form-input>
          </b-form-group>            
        </b-col>
      </b-row>
      <div class="d-block text-center mt-3">
        <b-button  v-show="loaderResiliation === false" variant="primary" @click="updateResiliation()">
          Confirmer
        </b-button>
        <b-button  v-show="loaderResiliation === true" variant="primary">
          <i class="fas fa-spinner fa-spin"></i>
        </b-button>        
      </div>
    </b-modal>
    <b-modal ref="modalPaiement" id="modalPaiement" hide-footer title="Paiement manuel">      
      <b-row>
        <b-col md="6">
          <b-form-group label="Montant">
            <b-form-input v-model="paiementTmp.montant" placeholder="Montant"></b-form-input>
          </b-form-group>            
        </b-col>
        <b-col md="6">
          <b-form-group label="Description">
            <b-form-input v-model="paiementTmp.titre" placeholder="Description"></b-form-input>
          </b-form-group>            
        </b-col>
      </b-row>     
      <div class="d-block text-center mt-3">
        <b-button variant="primary" @click="addPaiement()">
          Confirmer
        </b-button>
      </div>
    </b-modal>
    <b-modal ref="modalAnnulation" id="modalAnnulation" hide-footer title="Resiliation">      
      <div class="d-block text-center">
        Etes vous sûre de vouloir annuler la souscription ?
      </div>
      <div class="d-block text-center mt-3">
        <b-button variant="primary" @click="updateAnnulation()">
          Confirmer
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "",
  components: {
  },
  data: () => ({
    edit:false,
    loader_resi: false,
    loadMethod: false,
    loaderResiliation: false,
    params:false,
    souscription:false,
    user:{},
    proprietaire:{},
    sinistres:[],
    events:[],
    paiements:[],
    commentaireTmp:null,
    resiliation:{},
    paiementTmp:{},
    cb:{
      type_id:2,
      nb:null,
      name:null,
      date:null,
      cvv:null
    },
    rib:{
      type_id:1,
      iban: null,
      titulaire: null,
      bic: null
    }
  }),
  watch:{
    'cb.nb'(v){
      if(v === null){
        return false
      }
      this.cb.nb = v.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    },
    'rib.iban'(v){
      if(v === null){
        return false
      }
      this.rib.iban = v.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    },
    'cb.date'(v){
      if(v === null){
        return false;
      }
      if(v.length === 2){
        this.cb.date +="/";
      }
    }
  },
  methods: {
    sinistreStatus(id){
      return this.params.SinistreStatus.find(s => s.id === id)
    },
    sinistreType(id){
      return this.params.SinistreType.find(s => s.id === id)
    },
    addCommentaire(){
      if(this.souscription.commentaire === null){
        this.souscription.commentaire = "";
      }
      var date = new Date();
      var day = date.getDate() < 10 ? "0"+date.getDate() : date.getDate();
      var month = date.getMonth() < 10 ? '0'+(date.getMonth() + 1) : date.getMonth() + 1;
      var year = date.getFullYear();
      var hour = date.getHours() < 10 ? "0"+date.getHours() : date.getHours();
      var minute = date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();
      var txt = `${this.commentaireTmp} | par ${this.$store.api.user.prenom} ${this.$store.api.user.nom} le ${day}/${month}/${year} à ${hour}:${minute}`;
      this.souscription.commentaire += txt;
      this.souscription.commentaire += "\n";
      this.commentaireTmp = null;
      setTimeout(() => {
        var textarea = document.getElementById('commentaire_input');
        textarea.scrollTop = textarea.scrollHeight;
      },5);
      this.save('commentaire');
    },
    paiementStatus(id){
      var check = this.params.PaiementStatus.find(p => p.id === id);
      if(check === undefined){
        return {};
      }
      return check
    },
    save(type){
      var params = {
        type:type,
        souscription: this.souscription,
        user: this.user,
        proprietaire: this.proprietaire
      }
      this.$store.api.ajax('/admin/inscrits/update', params, (res) => {
        if(res.status === true){
          this.souscription = res.data.souscription;
          this.user = res.data.user;
          this.proprietaire = res.data.proprietaire;
          this.$toastr.s("Sauvegarder");
        }
      })
    },
    getCoupDur(){
      var tmp = this.souscription.loyer;
      if(this.souscription.meublee === true){
        tmp = tmp*2;
      }
      tmp = (tmp*5.67)/100
      tmp = tmp/12;
      tmp = tmp*1.15;
      return Math.ceil(tmp);
    },
    getResiliation(){
      if(this.loader_resi === true){
        return false;
      }
      this.loader_resi = true;
      this.$store.api.ajax('/admin/resiliation/'+this.souscription.id, null, res => {
        if(res.status === true){
          this.resiliation = res.data;
          this.$refs.modalResiliation.show();
        }
        this.loader_resi = false;
      })
    },
    updateResiliation(){
      if(this.loaderResiliation === true){
        return false;
      }
      this.loaderResiliation = true;
      this.$store.api.ajax('/admin/resiliation/update', this.resiliation, res => {
        if(res.status === true){
          this.souscription = res.data;
          this.$refs.modalResiliation.hide();
        }
      });
    }, 
    updateAnnulation(){
      this.$store.api.ajax('/admin/annulation/'+this.souscription.id, null, res => {
        if(res.status === true){
          this.souscription = res.data.souscription;
          this.events = res.data.events;
          this.$refs.modalAnnulation.hide();
        }
      });
    },
    price(){
      var params = {
        loyer: this.souscription.loyer,
        meublee: this.souscription.meublee
      }
      this.$store.api.ajax('/zero-depot/prix', params, res => {
        if(res.status === true){          
          var prix = res.data;
          if(this.souscription.coup_dur === true){
            prix += this.getCoupDur();
          }
          this.souscription.prix_mensuel = prix;
        }
      })
    },
    emailProprietaire(){
      this.$store.api.ajax('/admin/email/proprietaire/'+this.souscription.id, null, res => {
        if(res.status === true){          
          this.commentaireTmp = "Relance mail signature propriétaire envoyé";
          this.addCommentaire();          
        }
      })
    },
    addPaiement(){
      this.paiementTmp.souscription_id = this.souscription.id;      
      this.$store.api.ajax('/admin/paiement', this.paiementTmp, res => {
        if(res.status === true){
          this.$refs.modalPaiement.hide();
          this.paiementTmp = {};
        }
      })
    },
    changePaiement(paiement, status_id){
      paiement.status_id = status_id;
      this.$store.api.ajax('/admin/paiement/update', paiement, res => {
        if(res.status === true){
          this.$toastr.s("Sauvegarder");
        }
      })
    },
    checkCB(){
      if(this.cb.nb === null){
        return false;
      }
      var cc = this.cb.nb;
      cc = cc.replaceAll(" ","");
      var res = false;
      var amex = new RegExp('^3[47][0-9]+$');
      var visa = new RegExp('^4[0-9]+$');
      var mastercard = new RegExp('^5[1-5][0-9]+$');
      var mastercard2 = new RegExp('^2[2-7][0-9]+$');
      if (visa.test(cc)) {
        res = 'ic-visa.svg';
      }
      if (amex.test(cc)) {
        res = 'ic_amex.svg';
      }
      if (mastercard.test(cc) || mastercard2.test(cc)) {
        res = 'ic_ mastercard.svg';
      }
      return res;
    },
    saveMethod(){
      if(this.loadMethod === true){
        return false;
      }
      this.loadMethod = true;
      var params = {
        method: this.souscription.paiement.type_id === 1 ? this.rib : this.cb,
        s_id: this.souscription.id
      }
      this.$store.api.ajax('/admin/paiement/method', params, res => {
        if(res.status === true){
          this.$toastr.s("Sauvegarder");
          location.reload();
        }
        this.loadMethod = false;
      })
    }
  },
  computed:{
    souscriptionStatus(){
      return this.params.SouscriptionStatus.find(s => s.id === this.souscription.status_id)
    }
  },
  beforeMount() {
    this.$store.api.ajax('/admin/params', null, (res) => {
      if(res.status === true){
        this.params = res.data        
      }
    })
  },
  mounted() {
    this.$store.api.ajax('/admin/inscrits/'+this.$route.params.id, null, (res) => {
      if(res.status === true){
        this.souscription = res.data.souscription;
        this.user = res.data.user;
        this.proprietaire = res.data.proprietaire;
        this.sinistres = res.data.sinistres;
        this.events = res.data.events;
        this.paiements = res.data.paiements;        
      }
    })
  }
}
</script>
<style media="screen">
.btn.dropdown-toggle{
  width: 100%;
  height: 100%;
}
</style>
